import Vue from 'vue'
import VueRouter from 'vue-router'
import {Role} from '@/helpers/role'
import Edit from "@/views/Tickets/Edit.vue";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    component: () => import('../views/Errors/NotFoundView.vue'),
  },
  {
    path: '/access',
    name: 'access',
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk],
    },
    component: () => import('../views/Errors/NoAccess.vue')
  },
  {
    path: "/login",
    name: "login",
    component: () => import('../views/Auth/LoginView.vue'),
  },
  {
    path: "/protocol",
    name: "protocol",
    meta: {
      auth: [Role.lowOsiManager],
    },
    component: () => import('../views/LowOsi/ProtocolView.vue'),
  },
  {
    path: "/my-osi",
    name: "my_osi",
    meta: {
      auth: [Role.lowOsiManager],
    },
    component: () => import('../views/OsiRegistration/MyOSI.vue'),
  },
  {
    path: "/ustav",
    name: "ustav",
    meta: {
      auth: [Role.lowOsiManager],
    },
    component: () => import('../views/OsiRegistration/Ustav.vue'),
  },
  {
    path: "/leader",
    name: "leader",
    meta: {
      auth: [Role.lowOsiManager],
    },
    component: () => import('../views/LowOsi/LeaderChange.vue'),
  },
  {
    path: "/create-osi",
    name: "create_osi",
    meta: {
      auth: [Role.lowOsiManager],
    },
    component: () => import('../views/OsiRegistration/Application.vue'),
  },
  {
    path: "/sign",
    name: "sign",
    meta: {
      auth: [Role.lowOsiManager],
    },
    component: () => import('../views/OsiRegistration/Sign.vue'),
  },
  {
    path: "/low-osi-residents",
    name: "low_osi_residents",
    meta: {
      auth: [Role.lowOsiManager],
    },
    component: () => import('../views/LowOsi/Residents.vue'),
  },
  {
    path: "/registration",
    name: "registration",
    component: () => import('../views/Auth/RegistrationView.vue'),
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () => import('../views/Auth/ForgotView.vue'),
  },
  {
    path: '/osi-management',
    name: 'osi_management',
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/OsiManagement/List.vue'),
  },
  {
    path: '/osi-management/create',
    name: 'osi_management_create',
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/OsiManagement/Create.vue'),
  },
  {
    path: '/osi-management/:id/edit',
    name: 'osi_management_edit',
    props: true,
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/OsiManagement/Edit.vue'),
  },
  {
    path: '/uk-management',
    name: 'uk_management',
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/UkManagement/List.vue'),
  },
  {
    path: '/uk-management/create',
    name: 'uk_management_create',
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/UkManagement/Create.vue'),
  },
  {
    path: '/uk-management/:id/edit',
    name: 'uk_management_edit',
    props: true,
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/UkManagement/Edit.vue'),
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('@/views/News/List.vue'),
  },
  {
    path: '/',
    name: 'tickets',
    meta: {
      auth: [Role.osi, Role.uk, Role.superAdmin, Role.wd, Role.dispatcher, Role.emergencyDispatcher],
    },
    component: () => import('../views/Tickets/List.vue'),
  },
  {
    path: '/ticket/create',
    name: 'ticket_create',
    props: true,
    meta: {
      auth: [Role.osi, Role.uk, Role.superAdmin, Role.dispatcher],
    },
    component: () => import('../views/Tickets/Create.vue'),
  },
  {
    path: '/ticket/:id/edit',
    name: 'ticket_edit',
    props: true,
    meta: {
      auth: [Role.osi, Role.uk, Role.superAdmin, Role.wd, Role.dispatcher, Role.emergencyDispatcher],
    },
    component: Edit
  },
  {
    path: '/announcements',
    name: 'announcements',
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.lowOsiManager, Role.dispatcher],
    },
    component: () => import('../views/Announcements/List.vue'),
  },
  {
    path: '/announcements/create',
    name: 'announcements_create',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.lowOsiManager, Role.dispatcher],
    },
    component: () => import('../views/Announcements/Create.vue'),
  },
  {
    path: '/announcements/:id/edit',
    name: 'announcements_edit',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.lowOsiManager],
    },
    component: () => import('../views/Announcements/Edit.vue'),
  },
  /*{
    path: '/bank',
    name: 'bank',
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/Bank/List'),
  },*/
  {
    path: '/voting',
    name: 'voting',
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.lowOsiManager, Role.kzhk, Role.kzhkAdmin],
    },
    component: () => import('../views/Voting/List.vue'),
  },
  {
    path: '/voting/create',
    name: 'voting_create',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.lowOsiManager, Role.kzhk, Role.kzhkAdmin],
    },
    component: () => import('../views/Voting/Create.vue'),
  },
  {
    path: '/voting/:id',
    name: 'voting_edit',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.lowOsiManager, Role.kzhk, Role.kzhkAdmin],
    },
    component: () => import('../views/Voting/Edit.vue'),
  },
  {
    path: '/voting/:id/files',
    name: 'voting_files',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.lowOsiManager, Role.kzhk, Role.kzhkAdmin],
    },
    component: () => import('../views/Voting/Files.vue'),
  },
  {
    path: '/surveys',
    name: 'surveys',
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.lowOsiManager, Role.kzhk, Role.kzhkAdmin],
    },
    component: () => import('../views/Surveys/List.vue'),
  },
  {
    path: '/surveys/create',
    name: 'surveys_create',
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.lowOsiManager, Role.kzhk, Role.kzhkAdmin],
    },
    component: () => import('../views/Surveys/Create.vue'),
  },
  {
    path: '/surveys/:id',
    name: 'surveys_edit',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.lowOsiManager, Role.kzhk, Role.kzhkAdmin],
    },
    component: () => import('../views/Surveys/Edit.vue'),
  },
  {
    path: '/notifications',
    name: 'notifications',
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/Notifications/List.vue'),
  },
  {
    path: '/notifications/create',
    name: 'notifications_create',
    props: true,
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/Notifications/Create.vue'),
  },
  {
    path: '/notifications/:id/edit',
    name: 'notifications_edit',
    props: true,
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/Notifications/Edit.vue'),
  },
  {
    path: '/residents',
    name: 'residents',
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.lowOsiManager, Role.kzhk, Role.kzhkAdmin],
    },
    component: () => import('../views/Residents/List.vue'),
  },
  /*{
    path: '/residents/create',
    name: 'residents_create',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk],
    },
    component: () => import('../views/Residents/CreateExpense.vue'),
  },*/
  {
    path: '/residents/:id/edit',
    name: 'residents_edit',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.kzhk, Role.kzhkAdmin],
    },
    component: () => import('../views/Residents/Edit.vue'),
  },
  /*{
    path: '/access-systems',
    name: 'access_systems',
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk],
    },
    component: () => import('../views/AccessSystems/List.vue'),
  },
  {
    path: '/access-system/create',
    name: 'access_system_create',
    meta: {
      auth: [Role.osi],
    },
    component: () => import('../views/AccessSystems/CreateExpense.vue'),
  },
  {
    path: '/access-system/:id',
    name: 'access_system_edit',
    meta: {
      auth: [Role.osi],
    },
    component: () => import('../views/AccessSystems/EditExpense.vue'),
  },*/
  {
    path: '/employees',
    name: 'employees',
    meta: {
      auth: [Role.osi, Role.superAdmin],
    },
    component: () => import('../views/Employees/List.vue'),
  },
  {
    path: '/employees/create',
    name: 'employees_create',
    meta: {
      auth: [Role.osi, Role.superAdmin],
    },
    component: () => import('../views/Employees/Create.vue'),
  },
  {
    path: '/employees/:id/edit',
    name: 'employees_edit',
    props: true,
    meta: {
      auth: [Role.osi, Role.superAdmin],
    },
    component: () => import('../views/Employees/Edit.vue'),
  },
  {
    path: '/kzhk-users',
    name: 'kzhk_users',
    meta: {
      auth: [Role.superAdmin, Role.kzhkAdmin],
    },
    component: () => import('../views/KzhkUsers/List.vue'),
  },
  {
    path: '/mc-employees',
    name: 'mc_employees',
    meta: {
      auth: [Role.superAdmin, Role.uk],
    },
    component: () => import('../views/McEmployees/List.vue'),
  },
  {
    path: '/mc-employees/create',
    name: 'mc_employees_create',
    meta: {
      auth: [Role.uk, Role.superAdmin],
    },
    component: () => import('../views/McEmployees/Create.vue'),
  },
  {
    path: '/mc-employees/:id/edit',
    name: 'mc_employees_edit',
    props: true,
    meta: {
      auth: [Role.uk, Role.superAdmin],
    },
    component: () => import('../views/McEmployees/Edit.vue'),
  },
  {
    path: '/wd-employees',
    name: 'wd_employees',
    meta: {
      auth: [Role.superAdmin, Role.wd],
    },
    component: () => import('../views/WdEmployees/List.vue'),
  },
  {
    path: '/wd-employees/create',
    name: 'wd_employees_create',
    meta: {
      auth: [Role.wd, Role.superAdmin],
    },
    component: () => import('../views/WdEmployees/Create.vue'),
  },
  {
    path: '/wd-employees/:id/edit',
    name: 'wd_employees_edit',
    props: true,
    meta: {
      auth: [Role.wd, Role.superAdmin],
    },
    component: () => import('../views/WdEmployees/Edit.vue'),
  },
  {
    path: '/dispatchers',
    name: 'dispatchers',
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk],
    },
    component: () => import('../views/Dispatchers/List.vue'),
  },
  {
    path: '/dispatchers/create',
    name: 'dispatchers_create',
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk],
    },
    component: () => import('../views/Dispatchers/Create.vue'),
  },
  {
    path: '/dispatchers/:id/edit',
    name: 'dispatchers_edit',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk],
    },
    component: () => import('../views/Dispatchers/Edit.vue'),
  },
  {
    path: '/emergency-dispatchers',
    name: 'emergency_dispatchers',
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/EmergencyDispatchers/List.vue'),
  },
  {
    path: '/emergency-dispatchers/create',
    name: 'emergency_dispatchers_create',
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/EmergencyDispatchers/Create.vue'),
  },
  {
    path: '/emergency-dispatchers/:id/edit',
    name: 'emergency_dispatchers_edit',
    props: true,
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/EmergencyDispatchers/Edit.vue'),
  },
  {
    path: '/emergency-calls',
    name: 'emergency_calls',
    meta: {
      auth: [Role.superAdmin, Role.emergencyDispatcher],
    },
    component: () => import('../views/EmergencyCalls/List.vue'),
  },
  {
    path: '/emergency-calls/:id/edit',
    name: 'emergency_calls_edit',
    props: true,
    meta: {
      auth: [Role.emergencyDispatcher],
    },
    component: () => import('../views/EmergencyCalls/Edit.vue'),
  },
  {
    path: '/payments',
    name: 'payments',
    meta: {
      auth: [Role.superAdmin, Role.osi],
    },
    component: () => import('../views/Payments/List.vue'),
  },
  {
    path: '/payments/create',
    name: 'payments_create',
    meta: {
      auth: [Role.superAdmin, Role.osi],
    },
    component: () => import('../views/Payments/Create.vue'),
  },
  {
    path: '/payments/:id/edit',
    name: 'payments_edit',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.osi],
    },
    component: () => import('../views/Payments/Edit.vue'),
  },
  {
    path: '/chat/:id',
    name: 'chat',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.uk, Role.osi],
    },
    component: () => import('../views/Tickets/ChatView.vue'),
  },
  /*{
    path: '/services',
    name: 'services',
    meta: {
      auth: [Role.osi, Role.superAdmin, Role.uk],
    },
    component: () => import('../views/PaidServices/List.vue'),
  },*/
  {
    path: '/initiators',
    name: 'initiators',
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/Initiators/List.vue'),
  },
  {
    path: '/initiators/create',
    name: 'initiator_create',
    props: true,
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/Initiators/Create.vue'),
  },
  {
    path: '/initiators/:id',
    name: 'initiator',
    props: true,
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/Initiators/Edit.vue'),
  },
  {
    path: '/applications',
    name: 'applications',
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/Applications/List.vue'),
  },
  {
    path: '/applications/:id',
    name: 'application',
    props: true,
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/Applications/Edit.vue'),
  },
  {
    path: '/change-leaders',
    name: 'change_leaders',
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/ChangeLeaders/List.vue'),
  },
  {
    path: '/change-leaders/:id',
    name: 'change_leader',
    props: true,
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/ChangeLeaders/Edit.vue'),
  },
  {
    path: '/houses',
    name: 'houses',
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/Houses/List.vue'),
  },
  {
    path: '/houses/create',
    name: 'houses_create',
    props: true,
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/Houses/Create.vue'),
  },
  {
    path: '/houses/:id/edit',
    name: 'houses_edit',
    props: true,
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/Houses/Edit.vue'),
  },
  {
    path: '/ecp-sign',
    name: 'ecp_sign',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.kzhk, Role.kzhkAdmin],
    },
    component: () => import('../views/EcpSign/List.vue'),
  },
  {
    path: '/estimations',
    name: 'estimations',
    meta: {
      auth: [Role.osi],
    },
    component: () => import('../views/Estimation/List.vue'),
  },
  {
    path: '/estimations/create',
    name: 'estimations_create',
    meta: {
      auth: [Role.osi],
    },
    component: () => import('../views/Estimation/Create.vue'),
  },
  {
    path: '/estimations/:id',
    name: 'estimations_view',
    props: true,
    meta: {
      auth: [Role.osi],
    },
    component: () => import('../views/Estimation/Edit.vue'),
  },
  {
    path: '/check-owner',
    name: 'check_owner',
    props: true,
    meta: {
      auth: [Role.superAdmin],
    },
    component: () => import('../views/CheckOwner/List.vue'),
  },
  {
    path: '/edu',
    name: 'edu',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.uk, Role.kzhk, Role.kzhkAdmin],
    },
    component: () => import('../views/EduOsi/List.vue'),
  },
  {
    path: '/test',
    name: 'test',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.osi, Role.kzhk, Role.kzhkAdmin],
    },
    component: () => import('../views/EduOsi/Test.vue'),
  },
  {
    path: '/test-mc',
    name: 'test_mc',
    props: true,
    meta: {
      auth: [Role.superAdmin, Role.uk ],
    },
    component: () => import('../views/EduOsi/TestMc.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/Documents/Terms.vue'),
  },
  {
    path: '/ofert',
    name: 'ofert',
    component: () => import('../views/Documents/Ofert.vue'),
  },
  {
    path: '/user-agreement',
    name: 'user_agreement',
    component: () => import('../views/Documents/UserAgreement.vue'),
  },
  {
    path: '/user-data',
    name: 'user_data',
    component: () => import('../views/Documents/UserData.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/Documents/Agreement.vue'),
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import('../views/Logs/List.vue'),
    meta: {
      auth: [Role.superAdmin]
    }
  },
  {
    path: '/freedom-cash-flows',
    name: 'freedom_cash_flows',
    meta: {
      auth: [Role.kzhkAdmin],
    },
    component: () => import('../views/FreedomCashFlows/List.vue'),
  },
  {
    path: '/freedom-cash-flows/create',
    name: 'freedom_cash_flows_create',
    props: true,
    meta: {
      auth: [Role.kzhkAdmin],
    },
    component: () => import('../views/FreedomCashFlows/Create.vue'),
  },
  {
    path: '/freedom-cash-flows/:id/edit',
    name: 'freedom_cash_flows_edit',
    props: true,
    meta: {
      auth: [Role.kzhkAdmin],
    },
    component: () => import('../views/FreedomCashFlows/Edit.vue'),
  },
  {
    path: '/tax-report',
    name: 'tax_report',
    props: true,
    meta: {
      auth: [Role.osi],
    },
    component: () => import('../views/TaxReport/Index.vue')
  },
  {
    path: '/online-accounting',
    name: 'online_accounting',
    props: true,
    meta: {
      auth: [Role.osi],
    },
    component: () => import('../views/OnlineAccounting/List.vue')
  },
  {
    path: '/online-accounting/expense/create',
    name: 'online_accounting_expense_create',
    props: true,
    meta: {
      auth: [Role.osi],
    },
    component: () => import('../views/OnlineAccounting/CreateExpense.vue')
  },
  {
    path: '/online-accounting/expense/:id/edit',
    name: 'online_accounting_expense_edit',
    props: true,
    meta: {
      auth: [Role.osi],
    },
    component: () => import('../views/OnlineAccounting/EditExpense.vue')
  },
  {
    path: '/online-accounting/income/create',
    name: 'online_accounting_income_create',
    props: true,
    meta: {
      auth: [Role.osi],
    },
    component: () => import('../views/OnlineAccounting/CreateIncome.vue')
  },
  {
    path: '/online-accounting/income/:id/edit',
    name: 'online_accounting_income_edit',
    props: true,
    meta: {
      auth: [Role.osi],
    },
    component: () => import('../views/OnlineAccounting/EditIncome.vue')
  },
  {
    path: '/online-accounting/balance/create',
    name: 'online_accounting_balance_create',
    props: true,
    meta: {
      auth: [Role.osi],
    },
    component: () => import('../views/OnlineAccounting/CreateBalance.vue')
  },
  {
    path: '/online-accounting/balance/:id/edit',
    name: 'online_accounting_balance_edit',
    props: true,
    meta: {
      auth: [Role.osi],
    },
    component: () => import('../views/OnlineAccounting/EditBalance.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = JSON.parse(localStorage.getItem("u_user"));
  const { auth } = to.meta;

  if (currentUser && to.name === 'login') {
    next('/');
  }

  if (auth) {
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/login', query: { returnUrl: to.path } });
    }
    // check if route is restricted by role
    if (auth.length && !auth.includes(currentUser.role)) {

      // role not authorised so redirect to home page
      return next({name: 'access'})
    }
  }

  if (to.name === 'logs' && (store.state.phone === '+7 (700) 000-00-00' || store.state.phone === '+7 (700) 000-00-01')) {
    next()
  }

  next();
});

export default router
